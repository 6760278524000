```vue
<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{
  region: {
    name: string
    description: string
    capital: string
    population: string
    communes: Array<{
      name: string
      description: string
      population: string
      type: 'urbaine' | 'rurale'
      keyFeatures: string[]
      administration: {
        mayor: string
        councilMembers: number
        services: string[]
      }
    }>
    keyFeatures: string[]
    image: string
  }
}>()

const selectedCommune = ref(props.region.communes[0])
</script>

<template>
  <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
    <!-- En-tête de la région -->
    <div class="relative h-64">
      <img :src="region.image" :alt="region.name" class="w-full h-full object-cover">
      <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
        <div class="absolute bottom-0 p-6 text-white">
          <h2 class="text-3xl font-bold mb-2">{{ region.name }}</h2>
          <p class="text-lg opacity-90">Préfecture: {{ region.capital }}</p>
          <p class="opacity-90">Population: {{ region.population }}</p>
        </div>
      </div>
    </div>

    <!-- Description et caractéristiques -->
    <div class="p-6">
      <p class="text-gray-600 dark:text-gray-300 mb-6">{{ region.description }}</p>
      
      <div class="mb-8">
        <h3 class="text-xl font-semibold mb-4 dark:text-white">Points clés</h3>
        <ul class="grid md:grid-cols-2 gap-4">
          <li 
            v-for="feature in region.keyFeatures" 
            :key="feature"
            class="flex items-center space-x-2 text-gray-600 dark:text-gray-300"
          >
            <span class="w-2 h-2 bg-blue-500 rounded-full"></span>
            <span>{{ feature }}</span>
          </li>
        </ul>
      </div>

      <!-- Communes -->
      <div>
        <h3 class="text-xl font-semibold mb-4 dark:text-white">Communes</h3>
        
        <!-- Navigation des communes -->
        <div class="flex space-x-4 mb-6 overflow-x-auto pb-2">
          <button
            v-for="commune in region.communes"
            :key="commune.name"
            @click="selectedCommune = commune"
            class="px-4 py-2 rounded-full transition-colors whitespace-nowrap"
            :class="selectedCommune.name === commune.name
              ? 'bg-blue-500 text-white'
              : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'"
          >
            {{ commune.name }}
          </button>
        </div>

        <!-- Détails de la commune sélectionnée -->
        <div class="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-6">
          <div class="flex justify-between items-start mb-4">
            <div>
              <h4 class="text-lg font-semibold dark:text-white">
                {{ selectedCommune.name }}
              </h4>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Commune {{ selectedCommune.type }} | Population: {{ selectedCommune.population }}
              </p>
            </div>
            <div class="text-right">
              <p class="text-sm font-medium dark:text-white">Administration</p>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Maire: {{ selectedCommune.administration.mayor }}
              </p>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Conseil municipal: {{ selectedCommune.administration.councilMembers }} membres
              </p>
            </div>
          </div>

          <p class="text-gray-600 dark:text-gray-300 mb-4">
            {{ selectedCommune.description }}
          </p>

          <div class="space-y-4">
            <div>
              <h5 class="font-medium dark:text-white mb-2">Services municipaux:</h5>
              <ul class="grid grid-cols-2 gap-2 text-sm text-gray-600 dark:text-gray-300">
                <li 
                  v-for="service in selectedCommune.administration.services" 
                  :key="service"
                  class="flex items-center space-x-2"
                >
                  <svg class="w-4 h-4 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>{{ service }}</span>
                </li>
              </ul>
            </div>

            <div>
              <h5 class="font-medium dark:text-white mb-2">Points d'intérêt:</h5>
              <ul class="list-disc list-inside text-gray-600 dark:text-gray-300">
                <li v-for="feature in selectedCommune.keyFeatures" :key="feature">
                  {{ feature }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
```