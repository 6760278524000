import { createRouter, createWebHistory } from 'vue-router'
import StartScreen from '../views/StartScreen.vue'
import MainMenu from '../views/MainMenu.vue'
import OurHistory from '../views/OurHistory.vue'
import OurCulture from '../views/OurCulture.vue'
import OurLand from '../views/OurLand.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Accueil',
      component: StartScreen
    },
    {
      path: '/menu',
      name: 'Rubriques',
      component: MainMenu
    },
    {
      path: '/our-history',
      name: 'Notre Histoire',
      component: OurHistory
    },
    {
      path: '/our-culture',
      name: 'Notre Culture',
      component: OurCulture
    },
    {
      path: '/our-land',
      name: 'Notre Terre',
      component: OurLand
    }
  ]
})

export default router