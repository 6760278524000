import { defineStore } from 'pinia'
import { usePreferredDark } from '@vueuse/core'

export const useThemeStore = defineStore('theme', {
  state: () => ({
    isDark: usePreferredDark().value
  }),
  actions: {
    toggleTheme() {
      this.isDark = !this.isDark
      this.applyTheme()
    },
    initTheme() {
      this.applyTheme()
    },
    applyTheme() {
      document.documentElement.classList.toggle('dark', this.isDark)
      // Stocke la préférence dans localStorage
      localStorage.setItem('theme', this.isDark ? 'dark' : 'light')
    }
  },
  persist: true
})