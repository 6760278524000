export default {
  nav: {
    home: 'Home',
    ourHistory: 'Our History',
    ourCulture: 'Our Culture',
    ourLand: 'Our Land'
  },
  menu: {
    title: 'Tan mǐtɔ́n, ayǐkúngbàn mǐtɔ́n',
    subtitle: 'Our history, our heritage',
    ourHistory: 'Our History',
    ourHistoryDesc: 'Explore the fascinating history of Benin through the ages',
    ourLand: 'Our Land',
    ourLandDesc: 'Discover the diversity of Benin\'s landscapes and regions',
    ourCulture: 'Our Culture',
    ourCultureDesc: 'Dive into Benin\'s cultural richness and traditions'
  }
}