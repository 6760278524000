import type { MediaItem } from '@/types'

export const couffoMedia: MediaItem[] = [
  {
    id: 'rizieres-aplahoue',
    type: 'image',
    src: '.\\images\\history\\riziere_aplahoue.webp',
    title: 'Rizières d\'Aplahoué',
    description: 'Vue aérienne des rizières d\'Aplahoué pendant la saison des cultures',
    credits: '© Office du Tourisme du Couffo 2024',
    category: 'agriculture'
  },
  {
    id: 'agriculteurs-djakotomey',
    type: 'image',
    src: '.\\images\\history\\agriculteurs-djakotomey.webp',
    title: 'Agriculteurs de Djakotomey',
    description: 'Agriculteurs locaux travaillant dans les champs de riz à Djakotomey',
    credits: '© Association des Riziculteurs du Couffo 2024',
    category: 'agriculture'
  },
  {
    id: 'musee-aplahoue',
    type: 'image',
    src: '.\\images\\history\\musee-aplahoue.webp',
    title: 'Musée d\'Histoire Locale d\'Aplahoué',
    description: 'Façade principale du Musée d\'Histoire Locale d\'Aplahoué',
    credits: '© Direction du Patrimoine Culturel 2024',
    category: 'culture'
  },
  {
    id: 'musee-djakotomey',
    type: 'image',
    src: '.\\images\\history\\musee-djakotomey.webp',
    title: 'Collection du Musée de Djakotomey',
    description: 'Collection d\'objets traditionnels au Musée de Djakotomey',
    credits: '© Musée de Djakotomey 2024',
    category: 'culture'
  }
]

export const mediaLinks = {
  gallery: '/gallery/couffo',
  virtualTour: '/virtual-tour/museums',
  documentation: '/docs/history/couffo'
}