<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useMotion } from '@vueuse/motion'

const router = useRouter()

const menuItems = ref([
  {
    title: 'Notre Histoire',
    icon: '📚',
    route: '/our-history',
    description: 'Explorez l\'histoire fascinante du Bénin à travers les âges'
  },
  {
    title: 'Notre Terre',
    icon: '🗺️',
    route: '/our-land',
    description: 'Découvrez la diversité des paysages et régions du Bénin'
  },
  {
    title: 'Notre Culture',
    icon: '🎭',
    route: '/our-culture',
    description: 'Plongez dans la richesse culturelle et les traditions béninoises'
  }
])

const menuContainer = ref(null)

useMotion(menuContainer, {
  initial: { y: 50, opacity: 0 },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 500
    }
  }
})

const navigateTo = (route: string) => {
  router.push(route)
}
</script>

<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
    <div class="custom-container">
      <div ref="menuContainer" class="grid md:grid-cols-3 gap-8">
        <div
          v-for="item in menuItems"
          :key="item.title"
          class="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8 cursor-pointer transform hover:scale-105 transition-all duration-300"
          @click="navigateTo(item.route)"
        >
          <div class="text-4xl mb-4">{{ item.icon }}</div>
          <h2 class="text-2xl font-bold mb-3 dark:text-white">{{ item.title }}</h2>
          <p class="text-gray-600 dark:text-gray-300">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>