<script setup lang="ts">
import type { MediaItem } from '@/types'
import { ref } from 'vue'
import ImageWithFallback from './ImageWithFallback.vue'
import VideoPlayer from './VideoPlayer.vue'

const props = defineProps<{
  items: MediaItem[]
  columns?: number
}>()

const selectedItem = ref<MediaItem | null>(null)

const openLightbox = (item: MediaItem) => {
  selectedItem.value = item
}

const closeLightbox = () => {
  selectedItem.value = null
}
</script>

<template>
  <div class="grid gap-6" :class="`md:grid-cols-${props.columns || 2}`">
    <div
      v-for="item in items"
      :key="item.id"
      class="relative group cursor-pointer overflow-hidden rounded-lg shadow-lg"
      @click="openLightbox(item)"
    >
      <template v-if="item.type === 'image'">
        <ImageWithFallback
          :src="item.src"
          :alt="item.title"
          class="w-full h-[300px] object-cover transition-transform duration-300 group-hover:scale-105"
        />
      </template>
      
      <template v-else-if="item.type === 'video'">
        <div class="aspect-video">
          <VideoPlayer
            :src="item.src"
            :poster="item.poster"
            :title="item.title"
          />
        </div>
      </template>

      <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div class="absolute bottom-0 left-0 right-0 p-4 text-white">
          <h3 class="text-lg font-semibold mb-1">{{ item.title }}</h3>
          <p class="text-sm opacity-90">{{ item.description }}</p>
          <p class="text-xs mt-2 opacity-75">{{ item.credits }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Lightbox -->
  <div
    v-if="selectedItem"
    class="fixed inset-0 z-50 bg-black/90 flex items-center justify-center"
    @click.self="closeLightbox"
  >
    <div class="max-w-4xl w-full p-4">
      <template v-if="selectedItem.type === 'image'">
        <img
          :src="selectedItem.src"
          :alt="selectedItem.title"
          class="w-full h-auto max-h-[80vh] object-contain"
        />
      </template>
      
      <template v-else-if="selectedItem.type === 'video'">
        <div class="aspect-video">
          <VideoPlayer
            :src="selectedItem.src"
            :title="selectedItem.title"
            :poster="selectedItem.poster"
          />
        </div>
      </template>

      <div class="mt-4 text-white">
        <h3 class="text-xl font-semibold mb-2">{{ selectedItem.title }}</h3>
        <p class="text-gray-300">{{ selectedItem.description }}</p>
        <p class="text-sm text-gray-400 mt-2">{{ selectedItem.credits }}</p>
      </div>

      <button
        @click="closeLightbox"
        class="absolute top-4 right-4 text-white hover:text-gray-300"
      >
        <span class="sr-only">Fermer</span>
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </div>
</template>