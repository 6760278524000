<script setup lang="ts">
import { ref } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import RegionDetails from '@/components/RegionDetails.vue'
import MediaGalleryGrid from '@/components/MediaGalleryGrid.vue'
import { couffoData } from '@/data/geography/couffo'
import { couffoMedia, mediaLinks } from '@/data/geography/media'

const selectedRegion = ref(couffoData)
const agricultureMedia = couffoMedia.filter(item => item.category === 'agriculture')
const cultureMedia = couffoMedia.filter(item => item.category === 'culture')
</script>

<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900">
    <PageHeader
      title="Notre Terre"
      description="Découvrez la richesse géographique du Bénin"
    />

    <main class="custom-container py-12 space-y-16">
      <!-- Agriculture Section -->
      <section>
        <h2 class="text-3xl font-bold mb-6 dark:text-white">Agriculture Traditionnelle</h2>
        <MediaGalleryGrid :items="agricultureMedia" :columns="2" />
      </section>

      <!-- Region Details -->
      <section>
        <RegionDetails :region="selectedRegion" />
      </section>

      <!-- Cultural Heritage -->
      <section>
        <h2 class="text-3xl font-bold mb-6 dark:text-white">Patrimoine Culturel</h2>
        <MediaGalleryGrid :items="cultureMedia" :columns="2" />
      </section>

      <!-- Quick Links -->
      <section class="bg-white dark:bg-gray-800 rounded-lg p-8">
        <h2 class="text-2xl font-bold mb-6 dark:text-white">Ressources Complémentaires</h2>
        <div class="grid md:grid-cols-3 gap-6">
          <a
            v-for="(link, key) in mediaLinks"
            :key="key"
            :href="link"
            class="flex items-center p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
          >
            <span class="text-lg font-medium dark:text-white">
              {{ key === 'gallery' ? 'Galerie Photo' : 
                 key === 'virtualTour' ? 'Visite Virtuelle' : 
                 'Documentation' }}
            </span>
            <svg class="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </div>
      </section>
    </main>
  </div>
</template>