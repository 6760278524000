<script setup lang="ts">
import { ref, computed } from 'vue'

const props = defineProps<{
  src: string
  poster?: string
  title: string
}>()

const isYouTube = computed(() => props.src.includes('youtube.com/embed/'))
const videoRef = ref<HTMLIFrameElement | HTMLVideoElement | null>(null)
const isPlaying = ref(false)

const togglePlay = () => {
  if (isYouTube.value && videoRef.value) {
    const iframe = videoRef.value as HTMLIFrameElement
    const command = isPlaying.value ? 'pauseVideo' : 'playVideo'
    iframe.contentWindow?.postMessage(`{"event":"command","func":"${command}","args":""}`, '*')
    isPlaying.value = !isPlaying.value
  }
}
</script>

<template>
  <div class="relative rounded-lg overflow-hidden bg-black">
    <iframe
      v-if="isYouTube"
      ref="videoRef"
      :src="src"
      class="w-full aspect-video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <video
      v-else
      ref="videoRef"
      :src="src"
      :poster="poster"
      class="w-full aspect-video"
      @click="togglePlay"
    />
  </div>

  <h3 class="text-lg font-semibold mt-2 dark:text-white">{{ title }}</h3>
</template>