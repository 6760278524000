<script setup lang="ts">
import { ref } from 'vue'
import { traditions } from '@/data/traditions'
import { mediaService } from '@/services/mediaService'

const videos = ref(mediaService.getVideoContent('traditions'))
</script>

<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900 py-12">
    <div class="custom-container">
      <h1 class="text-4xl font-bold mb-8 text-center dark:text-white">
        Notre Culture
      </h1>

      <!-- Liste des traditions -->
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div
          v-for="tradition in traditions"
          :key="tradition.name"
          class="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden"
        >
          <img
            :src="tradition.image"
            :alt="tradition.name"
            class="w-full h-48 object-cover"
          >
          <div class="p-6">
            <h3 class="text-xl font-semibold mb-2 dark:text-white">
              {{ tradition.name }}
            </h3>
            <p class="text-gray-600 dark:text-gray-300">
              {{ tradition.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>