import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { clickOutside } from './directives/clickOutside'
import i18n from './i18n'
import './style.css'
import App from './App.vue'
import router from './router'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)
app.directive('click-outside', clickOutside)
app.use(pinia)
app.use(router)
app.use(i18n)
app.mount('#app')