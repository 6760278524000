```vue
<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{
  src: string
  alt: string
  fallbackSrc?: string
}>()

const imgSrc = ref(props.src)
const hasError = ref(false)

const handleError = () => {
  if (!hasError.value && props.fallbackSrc) {
    hasError.value = true
    imgSrc.value = props.fallbackSrc
  }
}
</script>

<template>
  <img
    :src="imgSrc"
    :alt="alt"
    @error="handleError"
    class="transition-opacity duration-300"
    :class="{ 'opacity-0': hasError }"
  >
</template>
```