export class MediaService {
  private readonly VIDEO_CONTENT = {
    history: [
      {
        id: 'benin-history',
        title: 'Histoire du Bénin',
        description: 'Un aperçu de l\'histoire fascinante du Bénin',
        type: 'video',
        src: 'https://www.youtube.com/embed/hCWVDD6XVaI',
        poster: '/images/history/ancient-kingdoms.webp'
      },
      {
        id: 'dahomey-kingdom',
        title: 'Le Royaume du Dahomey',
        description: 'L\'histoire du puissant royaume du Dahomey',
        type: 'video',
        src: 'https://www.youtube.com/embed/G5lTI4IGjQA',
        poster: '/images/history/dahomey-foundation.webp'
      }
    ]
  }

  getVideoContent(section: keyof typeof this.VIDEO_CONTENT) {
    return this.VIDEO_CONTENT[section] || []
  }

  getVideoById(id: string) {
    for (const section of Object.values(this.VIDEO_CONTENT)) {
      const video = section.find(v => v.id === id)
      if (video) return video
    }
    return null
  }
}

export const mediaService = new MediaService()