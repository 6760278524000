<template>
  <header class="fixed top-0 left-0 right-0 z-50 bg-white dark:bg-gray-800 shadow-md">
    <div class="h-10 bg-gray-100 dark:bg-gray-900 flex items-center justify-between px-4 select-none">
      <!-- Contrôles de fenêtre -->
      <div class="flex items-center space-x-2">
        <button v-for="(button, index) in windowControls" :key="index" @click="button.action"
          class="w-3 h-3 rounded-full transition-colors" :class="button.class" />
      </div>

      <!-- Titre de la fenêtre -->
      <div class="text-sm text-gray-600 dark:text-gray-300 font-medium">
        Tan Miton - {{ currentPageTitle }}
      </div>

      <!-- Placeholder pour la symétrie -->
      <div class="w-20"></div>
    </div>

    <!-- Barre d'outils principale -->
    <div class="h-12 flex items-center px-4 border-b dark:border-gray-700">
      <!-- Boutons de navigation -->
      <div class="flex items-center space-x-2">
        <button @click="goBack" :disabled="!canGoBack"
          class="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 disabled:opacity-50">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <button @click="goForward" :disabled="!canGoForward"
          class="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 disabled:opacity-50">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>

        <button @click="goHome" class="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>
        </button>
      </div>

      <!-- Contrôles supplémentaires -->
      <div class="ml-auto flex items-center space-x-2">
        <ThemeToggle />
        <LanguageSwitcher />
      </div>
    </div>
  </header>

  <!-- Spacer pour compenser la hauteur de la navbar fixe -->
  <div class="h-[88px]"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ThemeToggle from './ThemeToggle.vue'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const windowControls = [
  { class: 'bg-red-500 hover:bg-red-600', action: () => window.electronAPI?.close() },
  { class: 'bg-yellow-500 hover:bg-yellow-600', action: () => window.electronAPI?.minimize() },
  { class: 'bg-green-500 hover:bg-green-600', action: () => window.electronAPI?.maximize() }
]

const currentPageTitle = computed(() => {
  return t(`${route.name}`) || 'Accueil'
})

const canGoBack = computed(() => window.history.length > 1)
const canGoForward = computed(() => false)

const goBack = () => router.back()
const goForward = () => router.forward()
const goHome = () => router.push('/menu')
</script>