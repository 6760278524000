<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionRoot } from '@headlessui/vue'

const isOpen = ref(false)
const email = ref('')
const notificationEnabled = ref(false)

const features = [
  {
    title: 'Visite Virtuelle 360°',
    description: 'Explorez les sites historiques et culturels en réalité virtuelle',
    availability: 'Été 2024',
    icon: '🏛️'
  },
  {
    title: 'Quiz Interactifs',
    description: 'Testez vos connaissances sur l\'histoire et la culture du Bénin',
    availability: 'Printemps 2024',
    icon: '🎯'
  },
  {
    title: 'Audio Guide',
    description: 'Écoutez les récits et explications en plusieurs langues locales',
    availability: 'Automne 2024',
    icon: '🎧'
  },
  {
    title: 'Mode Hors-ligne',
    description: 'Accédez au contenu sans connexion internet',
    availability: 'Été 2024',
    icon: '📱'
  }
]

const subscribeToNotifications = () => {
  // Simulation d'inscription aux notifications
  notificationEnabled.value = true
  setTimeout(() => {
    notificationEnabled.value = false
    email.value = ''
    isOpen.value = false
  }, 2000)
}
</script>

<template>
  <div>
    <button
      @click="isOpen = true"
      class="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center space-x-2"
    >
      <span>Découvrir les Nouveautés</span>
      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
      </svg>
    </button>

    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="isOpen = false" class="relative z-50">
        <div class="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div class="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel class="w-full max-w-2xl bg-white dark:bg-gray-800 rounded-xl p-6 shadow-xl">
            <DialogTitle class="text-2xl font-bold mb-6 dark:text-white">
              Fonctionnalités à Venir
            </DialogTitle>

            <div class="grid md:grid-cols-2 gap-6">
              <div
                v-for="feature in features"
                :key="feature.title"
                class="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg"
              >
                <div class="text-3xl mb-3">{{ feature.icon }}</div>
                <h3 class="text-lg font-semibold mb-2 dark:text-white">
                  {{ feature.title }}
                </h3>
                <p class="text-gray-600 dark:text-gray-300 mb-2">
                  {{ feature.description }}
                </p>
                <p class="text-sm text-blue-600 dark:text-blue-400">
                  Disponible : {{ feature.availability }}
                </p>
              </div>
            </div>

            <div class="mt-8 border-t dark:border-gray-600 pt-6">
              <h4 class="text-lg font-semibold mb-4 dark:text-white">
                Restez informé
              </h4>
              <form @submit.prevent="subscribeToNotifications" class="flex space-x-4">
                <input
                  v-model="email"
                  type="email"
                  placeholder="Votre email"
                  class="flex-1 px-4 py-2 rounded-lg border dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                  required
                />
                <button
                  type="submit"
                  :disabled="notificationEnabled"
                  class="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 transition-colors"
                >
                  {{ notificationEnabled ? 'Inscrit !' : 'M\'avertir' }}
                </button>
              </form>
            </div>

            <button
              @click="isOpen = false"
              class="absolute top-4 right-4 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <span class="sr-only">Fermer</span>
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </DialogPanel>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>