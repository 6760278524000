import { IMAGE_PATHS } from '@/utils/imageConstants'
import type { Tradition } from '@/types'

export const traditions: Tradition[] = [
  {
    name: 'Zangbeto',
    description: 'Les Zangbetos, gardiens nocturnes traditionnels, sont représentés par des impressionnants masques en paille qui tourbillonnent lors des cérémonies. Ces gardiens de la nuit jouent un rôle crucial dans la sécurité communautaire et les traditions vodun.',
    region: 'Porto-Novo et région côtière',
    image: IMAGE_PATHS.TRADITIONS.ZANGBETO
  },
  {
    name: 'Vodun',
    description: 'Le Vodun, religion traditionnelle béninoise, est un système de croyances complexe qui honore les forces de la nature et les ancêtres. Cette pratique spirituelle influence profondément la vie quotidienne et les cérémonies traditionnelles.',
    region: 'Sud Bénin',
    image: IMAGE_PATHS.TRADITIONS.VODUN
  },
  {
    name: 'Gelede',
    description: 'Les masques et danses Gelede honorent le pouvoir mystique des femmes âgées et des ancêtres. Cette tradition, inscrite au patrimoine culturel immatériel de l\'UNESCO, se manifeste par des spectacles colorés et des masques finement sculptés.',
    region: 'Région Yoruba',
    image: IMAGE_PATHS.TRADITIONS.GELEDE
  }
]