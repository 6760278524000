export const IMAGE_PATHS = {
  HISTORY: {
    ANCIENT_KINGDOMS: 'images/history/ancient-kingdoms.webp',
    DAHOMEY_FOUNDATION: 'images/history/dahomey-foundation.webp',
    AMAZONS: 'images/history/amazons.webp',
    FRENCH_COLONIZATION: 'images/history/french-colonization.webp',
    INDEPENDENCE: 'images/history/independence.webp',
    MARXIST_PERIOD: 'images/history/marxist-period.webp',
    DEMOCRATIC_RENEWAL: 'images/history/democratic-renewal.webp',
    MODERN_ERA: 'images/history/modern-era.webp'
  },
  GEOGRAPHY: {
    ATACORA: 'images/geography/atacora.webp',
    BORGOU: 'images/geography/borgou.webp',
    LITTORAL: 'images/geography/littoral.webp',
    COUFFO: 'images/geography/couffo.webp'
  },
  TRADITIONS: {
    GELEDE: 'images/traditions/gelede.webp',
    VODUN: 'images/traditions/vodun.webp',
    ZANGBETO: 'images/traditions/zangbeto.webp'
  },
  LOGO: {
    DEFAULT: 'images/logo.svg',
    DARK: 'images/logo-dark.svg'
  }
} as const

export const getImagePath = (category: keyof typeof IMAGE_PATHS, image: string): string => {
  const categoryPaths = IMAGE_PATHS[category]
  const imagePath = categoryPaths[image as keyof typeof categoryPaths]
  if (!imagePath) {
    console.warn(`Image path not found for category: ${category}, image: ${image}`)
    return ''
  }
  return imagePath
}