<script setup lang="ts">
import { ref } from 'vue'
import { mediaService } from '@/services/mediaService'
import PageHeader from '@/components/PageHeader.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import UpcomingFeatures from '@/components/UpcomingFeatures.vue'

const videos = ref(mediaService.getVideoContent('history'))

const historicalImages = [
  {
    src: '.\\images\\history\\palais-abomey.webp',
    alt: 'Palais Royal d\'Abomey',
    caption: 'Vue historique du Palais Royal',
    credit: 'Photo par Historical Archives'
  },
  {
    src: '.\\images\\history\\architecture-royale.webp',
    alt: 'Architecture traditionnelle',
    caption: 'Architecture royale béninoise',
    credit: 'Photo par Cultural Heritage'
  }
]
</script>

<template>
  <div class="min-h-screen bg-gray-50 dark:bg-gray-900">
    <PageHeader
      title="Notre Histoire"
      description="Explorez l'histoire fascinante du Bénin à travers les âges"
    />

    <main class="custom-container py-12">
      <!-- Images historiques -->
      <section class="mb-16">
        <h2 class="text-3xl font-bold mb-8 dark:text-white">Patrimoine Historique</h2>
        <div class="grid md:grid-cols-2 gap-6">
          <div
            v-for="image in historicalImages"
            :key="image.src"
            class="relative group overflow-hidden rounded-lg shadow-lg"
          >
            <img
              :src="image.src"
              :alt="image.alt"
              class="w-full h-64 object-cover transform group-hover:scale-105 transition-transform duration-300"
            >
            <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
              <div class="absolute bottom-0 left-0 right-0 p-4 text-white">
                <p class="font-medium mb-1">{{ image.caption }}</p>
                <p class="text-sm opacity-75">{{ image.credit }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Vidéos -->
      <div class="grid gap-8">
        <div
          v-for="(video, index) in videos"
          :key="video.id"
          class="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
        >
          <VideoPlayer
            :src="video.src"
            :poster="video.poster"
            :title="video.title"
            :autoplay="index === 0"
          />
          <div class="p-6">
            <h3 class="text-xl font-bold mb-2 dark:text-white">{{ video.title }}</h3>
            <p class="text-gray-600 dark:text-gray-300">{{ video.description }}</p>
          </div>
        </div>
      </div>

      <!-- Fonctionnalités à venir -->
      <div class="mt-16 text-center">
        <UpcomingFeatures />
      </div>
    </main>
  </div>
</template>