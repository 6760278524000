export interface CommuneType {
  name: string
  description: string
  population: string
  type: 'urbaine' | 'rurale'
  keyFeatures: string[]
  administration: {
    mayor: string
    councilMembers: number
    services: string[]
  }
}

export interface CouffoDataType {
  id: string
  name: string
  capital: string
  population: string
  description: string
  communes: CommuneType[]
  keyFeatures: string[]
  image: string
  metadata: {
    keywords: string
    lastUpdated: string
    author: string
  }
}

export const couffoData: CouffoDataType = {
  id: 'couffo',
  name: 'Couffo',
  capital: 'Aplahoué',
  population: '745,328',
  description: 'Le département du Couffo, situé au sud-ouest du Bénin, est une région riche en traditions culturelles et en potentiel agricole. Avec une superficie de 2.404 km², ce territoire se distingue par ses terres fertiles, son patrimoine vodun vivace et son dynamisme commercial. Le département tire son nom de la rivière Couffo qui le traverse, créant une vallée fertile propice à l\'agriculture.',
  communes: [
    {
      name: 'Aplahoué',
      description: 'Chef-lieu du département, Aplahoué est un centre administratif et commercial majeur. Son marché régional est l\'un des plus importants du sud-ouest du Bénin, attirant des commerçants de tout le pays et des nations voisines. La ville est un carrefour stratégique pour le commerce transfrontalier avec le Togo.',
      population: '171,109',
      type: 'urbaine',
      keyFeatures: [
        'Grand marché régional bi-hebdomadaire',
        'Centre administratif départemental',
        'Production intensive de maïs et de manioc',
        'Carrefour commercial transfrontalier'
      ],
      administration: {
        mayor: 'Maxime Allossogbé',
        councilMembers: 19,
        services: [
          'État civil',
          'Services techniques',
          'Affaires sociales',
          'Développement local'
        ]
      }
    },
    {
      name: 'Djakotomey',
      description: 'Djakotomey se distingue par ses vastes palmeraies et son rôle central dans la production d\'huile de palme traditionnelle. La commune est également un centre important de la spiritualité vodun, avec de nombreux sites sacrés qui témoignent de la richesse culturelle de la région.',
      population: '134,028',
      type: 'rurale',
      keyFeatures: [
        'Production traditionnelle d\'huile de palme',
        'Sites vodun historiques',
        'Artisanat traditionnel florissant',
        'Festivals culturels annuels'
      ],
      administration: {
        mayor: 'Ulrich Sokègbé',
        councilMembers: 17,
        services: [
          'Développement rural',
          'Culture et tourisme',
          'Affaires sociales',
          'Services techniques'
        ]
      }
    },
    {
      name: 'Dogbo',
      description: 'Centre dynamique du textile traditionnel, Dogbo est réputé pour son marché hebdomadaire qui attire des acheteurs de toute la région. La commune est un pôle important de l\'artisanat textile, perpétuant des techniques de tissage ancestrales.',
      population: '103,057',
      type: 'urbaine',
      keyFeatures: [
        'Centre de production textile traditionnelle',
        'Marché hebdomadaire important',
        'Ateliers de tissage artisanal',
        'Centre de formation artisanale'
      ],
      administration: {
        mayor: 'Magloire Agossou',
        councilMembers: 15,
        services: [
          'Développement économique',
          'Formation professionnelle',
          'Urbanisme',
          'Action sociale'
        ]
      }
    },
    {
      name: 'Klouékanmè',
      description: 'Zone agricole majeure du Couffo, Klouékanmè est reconnue pour sa production vivrière diversifiée et ses techniques agricoles traditionnelles. La commune abrite plusieurs forêts sacrées qui jouent un rôle crucial dans la préservation de la biodiversité locale.',
      population: '128,597',
      type: 'rurale',
      keyFeatures: [
        'Production agricole diversifiée',
        'Forêts sacrées protégées',
        'Systèmes agricoles traditionnels',
        'Conservation de la biodiversité'
      ],
      administration: {
        mayor: 'Coffi Dieudonné GBEDJEKAN',
        councilMembers: 16,
        services: [
          'Agriculture',
          'Environnement',
          'Développement rural',
          'Affaires domaniales'
        ]
      }
    },
    {
      name: 'Lalo',
      description: 'Lalo est un centre spirituel majeur du Couffo, connu pour ses nombreux sites vodun et ses cérémonies traditionnelles. La commune combine harmonieusement agriculture moderne et pratiques traditionnelles, tout en préservant son riche patrimoine culturel.',
      population: '119,926',
      type: 'rurale',
      keyFeatures: [
        'Centres spirituels vodun',
        'Agriculture mixte moderne-traditionnelle',
        'Festivals culturels importants',
        'Sites historiques préservés'
      ],
      administration: {
        mayor: 'William T. FANGBEDJI',
        councilMembers: 15,
        services: [
          'Culture et patrimoine',
          'Agriculture',
          'Affaires sociales',
          'Développement local'
        ]
      }
    },
    {
      name: 'Toviklin',
      description: 'Toviklin se distingue par son riche patrimoine culturel et ses traditions artisanales préservées. La commune est un centre important de production artisanale, notamment dans la poterie et la sculpture traditionnelle, perpétuant des savoir-faire ancestraux.',
      population: '88,611',
      type: 'rurale',
      keyFeatures: [
        'Centre d\'artisanat traditionnel',
        'Ateliers de poterie et sculpture',
        'Cérémonies traditionnelles régulières',
        'Transmission des savoirs ancestraux'
      ],
      administration: {
        mayor: 'Rigobert TOZO',
        councilMembers: 13,
        services: [
          'Culture et artisanat',
          'Tourisme',
          'Formation professionnelle',
          'Action sociale'
        ]
      }
    }
  ],
  keyFeatures: [
    'Production agricole diversifiée et durable',
    'Riche patrimoine culturel vodun vivant',
    'Marchés traditionnels dynamiques',
    'Artisanat local préservé et valorisé',
    'Commerce transfrontalier actif',
    'Conservation des forêts sacrées'
  ],
  image: '/images/geography/couffo.webp',
  metadata: {
    keywords: 'Couffo, Bénin, Aplahoué, vodun, agriculture, traditions, commerce, artisanat',
    lastUpdated: '2024-03-07',
    author: 'Tan mǐtɔ́n Research Team'
  }
}