<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useMotion } from '@vueuse/motion'

const router = useRouter()
const startButton = ref(null)
const isLoading = ref(true)
const loadingProgress = ref(0)

const loadingSteps = [
  { message: 'Chargement des ressources...', duration: 1000 },
  { message: 'Préparation du contenu...', duration: 800 },
  { message: 'Initialisation...', duration: 500 }
]

useMotion(startButton, {
  initial: { scale: 0.8, opacity: 0 },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 15
    }
  }
})

const simulateLoading = async () => {
  for (let i = 0; i < loadingSteps.length; i++) {
    const step = loadingSteps[i]
    await new Promise(resolve => setTimeout(resolve, step.duration))
    loadingProgress.value = ((i + 1) / loadingSteps.length) * 100
  }
  isLoading.value = false
}

const startExploration = () => {
  router.push('/menu')
}

onMounted(() => {
  simulateLoading()
})
</script>

<template>
  <div class="min-h-screen flex flex-col justify-center bg-gradient-to-br from-blue-900 to-blue-600">
    <div class="text-center text-white">
      <h1 class="text-6xl font-bold mb-4">Tan mǐtɔn, ayikúngban mǐtɔn</h1>
      <p class="text-xl mb-8 opacity-90">Notre histoire, notre patrimoine</p>

      <div v-if="isLoading" class="flex flex-col items-center justify-center space-y-4">
        <div class="w-64 h-2 bg-white/20 rounded-full overflow-hidden">
          <div class="h-full bg-white transition-all duration-300 rounded-full"
            :style="{ width: `${loadingProgress}%` }" />
        </div>
        <p class="text-sm">
          {{ loadingSteps[Math.floor((loadingProgress / 100) * loadingSteps.length)]?.message }}
        </p>
      </div>

      <button v-else ref="startButton" @click="startExploration"
        class="px-12 py-4 bg-white text-blue-600 rounded-full text-xl font-semibold hover:bg-blue-50 transform hover:scale-105 transition-all duration-300">
        Démarrer
      </button>
    </div>
  </div>
</template>
