export default {
  nav: {
    home: 'Accueil',
    ourHistory: 'Notre Histoire',
    ourCulture: 'Notre Culture',
    ourLand: 'Notre Terre'
  },
  menu: {
    title: 'Tan mǐtɔ́n, ayǐkúngbàn mǐtɔ́n',
    subtitle: 'Notre histoire, notre patrimoine',
    ourHistory: 'Notre Histoire',
    ourHistoryDesc: 'Explorez l\'histoire fascinante du Bénin à travers les âges',
    ourLand: 'Notre Terre',
    ourLandDesc: 'Découvrez la diversité des paysages et régions du Bénin',
    ourCulture: 'Notre Culture',
    ourCultureDesc: 'Plongez dans la richesse culturelle et les traditions béninoises'
  }
}